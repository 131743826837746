<template>
  <div>
    <div class="tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-4">
      <div class="tw-px-4 tw-rounded tw-shadow-md tw-bg-white">
        <div class="tw-my-4 tw-flex tw-justify-between tw-gap-4 tw-items-center">
          <h2 class="tw-my-0">Aankoopkosten</h2>
          <router-link
            :to="{ name: 'PropertyFinancialEditPurchaseCosts' }"
            title="Bewerken"
            class="action tw-m-0 tw-bg-success tw-h-full tw-text-xs"
          >
            <i class="fas fa-pencil" />
            <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Bewerken</span>
          </router-link>
        </div>

        <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-3 tw-gap-0.5 tw-text-xs">
          <template v-if="isSales">
            <dt>Btw-stelsel <i class="far fa-globe-europe" /></dt>
            <dd>{{ financial.is_vat_system | yesno }}</dd>
          </template>
          <template v-if="financial.is_vat_system">
            <dt>Btw op volledige aankoop <i class="far fa-globe-europe" /></dt>
            <dd>{{ financial.vat_on_full_purchase_price | yesno }}</dd>
          </template>

          <dt>Prijs (aankoopbedrag)</dt>
          <dd>{{ currency(financial.price && financial.price.price) }}</dd>

          <template v-if="isSales && financial.is_vat_system && !financial.vat_on_full_purchase_price">
            <dt>- Grondwaarde</dt>
            <dd>{{ currency(financial.price_land_share) }}</dd>
            <dt>- Constructiewaarde</dt>
            <dd>{{ currency(financial.price_infrastructure) }}
            </dd>
          </template>

          <dt>Prijs zichtbaarheid</dt>
          <dd>{{ (financial.price && financial.price.published) ? 'Publiceer prijs' : 'Publiceer prijs niet' }}</dd>

          <template v-if="isSales">
            <template v-if="!financial.vat_on_full_purchase_price">
              <dt>Registratierechten {{ percentage(financial.registration_sale_fees_rate) }}</dt>
              <dd>{{ currency(financial.calculated_registration_sale_fees) }}</dd>
            </template>
            <template v-if="financial.is_vat_system">
              <dt>BTW {{ percentage(financial.vat_rate) }} <i class="far fa-globe-europe" /></dt>
              <dd>{{ currency(financial.calculated_vat) }}</dd>
            </template>

            <dt>Notariskosten <i class="far fa-globe-europe" /></dt>
            <dd>{{ currency(financial.notary_costs_estimate ? financial.notary_costs_estimate : 4000) }}</dd>

            <template v-if="financial.is_vat_system">
              <dt>Raming aansluitingskosten <i class="far fa-globe-europe" /></dt>
              <dd>{{ currency(financial.connection_costs_estimate) }}</dd>
            </template>

            <dt>Overige aankoopkosten</dt>
            <dd>{{ currency(financial.other_costs) }}</dd>
          </template>

          <template v-if="isRental">
            <dt>Kostprijs plaatsbeschrijving</dt>
            <dd>{{ currency(financial.cost_tenancy_inspection) }}</dd>
            <dt>Huurwaarborg</dt>
            <dd>{{ currency(financial.rental_deposit) }}</dd>
            <dt>Medeverhuring</dt>
            <dd>{{ financial.co_leasing | yes_no_unknown }}</dd>
          </template>
        </dl>

        <div
          v-if="isSales && financial.is_investment_property"
          class="tw-py-2.5 tw-text-lg tw-flex tw-justify-between tw-flex-wrap tw-gap-1 tw-font-semibold tw-text-success tw-border-t-0.5"
        >
          <span>Totale aankoopprijs</span>
          <span>{{ currency(financial.total_purchase_price) }}</span>
        </div>
      </div>

      <div v-if="isSales" class="tw-px-4 tw-rounded tw-shadow-md tw-bg-white">
        <div class="tw-my-4 tw-flex tw-justify-between tw-gap-4 tw-items-center">
          <h2 class="tw-my-0">Investeringsvastgoed</h2>
          <router-link
            :to="{ name: 'PropertyFinancialEdit', params: { formName: 'investment' } }"
            title="Bewerken"
            class="action tw-m-0 tw-bg-success tw-h-full tw-text-xs"
          >
            <i class="fas fa-pencil" />
            <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Bewerken</span>
          </router-link>
        </div>
        <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-3 tw-gap-0.5 tw-text-xs">
          <dt>Investeringsvastgoed <i class="far fa-globe-europe" /></dt>
          <dd>{{ financial.is_investment_property | yesno }}</dd>

          <template v-if="financial.is_investment_property">
            <template v-if="technical">
              <dt>Nieuwbouw <i class="far fa-globe-europe" /></dt>
              <dd>{{ property.is_newly_built | yesno }}</dd>
              <dt>Staat <i class="far fa-globe-europe" /></dt>
              <dd>{{ (technical.state && technical.state.name) || '-' }}</dd>
            </template>

            <dt>Rendement</dt>
            <dd>{{ percentage(financial.return_on_investment) }}</dd>
            <dt>Verhuurd <i class="far fa-globe-europe" /></dt>
            <dd>{{ financial.is_rented | yes_no_unknown }}</dd>
            <dt>Maandhuur</dt>
            <dd>{{ currency(financial.monthly_rent) }}</dd>
            <dt>Jaarhuur <i class="far fa-globe-europe" /></dt>
            <dd>{{ currency(financial.annual_rent) }}</dd>

            <router-link
              :to="{ name: 'PropertyFinancialCalculator' }"
              tag="button"
              title="Bewerken"
              class="tw-my-4 formulate-button tw-bg-success tw-col-span-3"
            >
              <i class="fas fa-calculator tw-mr-2" /> Berekenen
            </router-link>
          </template>
        </dl>
      </div>

      <div class="tw-px-4 tw-rounded tw-shadow-md tw-bg-white">
        <div class="tw-my-4 tw-flex tw-justify-between tw-gap-4 tw-items-center">
          <h2 class="tw-my-0">Kadastraal</h2>
          <router-link
            :to="{ name: 'PropertyFinancialEdit', params: { formName: 'cadastral' } }"
            title="Bewerken"
            class="action tw-m-0 tw-bg-success tw-h-full tw-text-xs"
          >
            <i class="fas fa-pencil" />
            <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Bewerken</span>
          </router-link>
        </div>

        <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-3 tw-gap-0.5 tw-text-xs">
          <dt>Kadastraal inkomen <i class="far fa-globe-europe" /></dt>
          <dd>{{ currency(financial.cadastral_income) }}</dd>
          <dt>Geïndexeerd kadastraal inkomen</dt>
          <dd>{{ currency(financial.cadastral_income_indexed) }}</dd>
          <dt>Jaartal v/h geïndexeerd kadastraal inkomen</dt>
          <dd>{{ financial.cadastral_income_indexed_year || '-' }}</dd>
          <dt>Kadastrale afdeling</dt>
          <dd>{{ financial.cadastral_department || '-' }}</dd>
          <dt>Kadastrale sectie</dt>
          <dd>{{ financial.cadastral_section || '-' }}</dd>
          <dt>Perceelnummer</dt>
          <dd>{{ financial.lot_number || '-' }}</dd>
          <dt>Partitienummer</dt>
          <dd>{{ financial.partition_number || '-' }}</dd>
          <dt>Kadastrale aard</dt>
          <dd>{{ financial.cadastral_nature || '-' }}</dd>
        </dl>
      </div>

      <div class="tw-px-4 tw-rounded tw-shadow-md tw-bg-white">
        <div class="tw-my-4 tw-flex tw-justify-between tw-gap-4 tw-items-center">
          <h2 class="tw-my-0">Vaste kosten</h2>
          <router-link
            :to="{ name: 'PropertyFinancialEdit', params: { formName: 'fixed-costs' } }"
            title="Bewerken"
            class="action tw-m-0 tw-bg-success tw-h-full tw-text-xs"
          >
            <i class="fas fa-pencil" />
            <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Bewerken</span>
          </router-link>
        </div>

        <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-3 tw-gap-0.5 tw-text-xs tw-pb-0">
          <dt>Maandelijks forfait <i class="far fa-globe-europe" /></dt>
          <dd>{{ currency(financial.monthly_costs) }}</dd>
          <dt>Maandelijkse provisie <i class="far fa-globe-europe" /></dt>
          <dd>{{ currency(financial.commission) }}</dd>
          <dt>Maandelijks forfait - extra informatie <i class="far fa-globe-europe" /></dt>
          <dd>{{ (financial.monthly_costs_description && financial.monthly_costs_description.text_nl) || '-' }}</dd>
          <dt>Maandelijkse provisie - extra informatie <i class="far fa-globe-europe" /></dt>
          <dd>{{ (financial.commission_description && financial.commission_description.text_nl) || '-' }}</dd>
        </dl>
      </div>

      <div class="tw-px-4 tw-rounded tw-shadow-md tw-bg-white">
        <div class="tw-my-4 tw-flex tw-justify-between tw-gap-4 tw-items-center">
          <h2 class="tw-my-0">Andere</h2>
          <router-link
            :to="{ name: 'PropertyFinancialEdit', params: { formName: 'others' } }"
            title="Bewerken"
            class="action tw-m-0 tw-bg-success tw-h-full tw-text-xs"
          >
            <i class="fas fa-pencil" />
            <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Bewerken</span>
          </router-link>
        </div>

        <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-3 tw-gap-0.5 tw-text-xs">
          <dt>Prijs per m²</dt>
          <dd>{{ currency(financial.price_per_square_meter) }}</dd>

          <template v-if="isSales">
            <dt>Lijfrente</dt>
            <dd>{{ currency(financial.annuity) }}</dd>
          </template>

          <dt>Erfpacht</dt>
          <dd>{{ currency(financial.ground_lease) }}</dd>
          <dt>Erfpacht jaarlijkse kostprijs</dt>
          <dd>{{ currency(financial.ground_lease_annual_cost) }}</dd>
          <dt>Onroerende voorheffing <i class="far fa-globe-europe" /></dt>
          <dd>{{ currency(financial.property_tax) }}</dd>
          <dt>Jaartal onroerende voorheffing</dt>
          <dd>{{ financial.property_tax_year || '-' }}</dd>
          <dt>Erfdienstbaarheid</dt>
          <dd>{{ financial.easement | yes_no_not_entered }}</dd>
          <dt>Erfdienstbaarheid - extra informatie</dt>
          <dd>{{ (financial.easement_description && financial.easement_description.text_nl) || '-' }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { currency, percentage } from '@/utils/helpers'
import { mapGetters } from 'vuex'
import { getPropertyFinancial, getPropertyTechnical, getInvestmentGridDefaultValues } from '@/services/properties'
import { propertyIsSales, propertyIsRental } from '@/utils/properties'

export default {
  name: 'PropertyFinancial',
  data () {
    return {
      financial: {},
      technical: {},
      financialParameters: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    propertyId () {
      return this.$route.params.id
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    isSales () {
      return propertyIsSales(this.property)
    },
    isRental () {
      return propertyIsRental(this.property)
    }
  },
  created () {
    this.init()
  },
  methods: {
    currency,
    percentage,
    async init () {
      try {
        const [financialResponse, technicalResponse] = await Promise.all([
          this.loadPropertyFinancial(this.propertyId),
          this.loadPropertyTechnical(this.propertyId)
        ])
        if (this.financial.is_investment_property) {
          await this.loadPropertyFinancialParameters(this.propertyId)
        }
        return [financialResponse, technicalResponse]
      } catch (error) {
        console.error(error)
      }
    },
    async loadPropertyFinancial (propertyId) {
      const response = await getPropertyFinancial(propertyId)
      this.financial = response.data
      return response
    },
    async loadPropertyTechnical (propertyId) {
      const response = await getPropertyTechnical(propertyId)
      this.technical = response.data
      return response
    },
    async loadPropertyFinancialParameters (propertyId) {
      const response = await getInvestmentGridDefaultValues(propertyId)
      this.financialParameters = response.data
      return response
    }
  }
}
</script>

<style scoped>
dd {
  @apply md:tw-text-right
}
dt {
  @apply md:tw-col-span-2
}
</style>
